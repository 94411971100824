$(function() {
	class Mask {

		constructor() {
			this.$ = $('#mask');
			this.$html = $('html');

			this.$close = this.$.find('#mask-close');

			let $div = $('<div />');

			if(this.$.length === 0) {
				this.$ = $div.clone().attr('id', 'mask').appendTo('body');
				$div.clone().attr('id', 'mask-fade').appendTo('body')
			}

			let $content = $div.clone().attr('id', 'mask-content-wrapper').appendTo(this.$);
			this.$inner = $div.clone().attr('id', 'mask-inner').appendTo($content);

			let $close = $('<a href="#" id="mask-close">stäng<span class="fa-close"></span></a>');
			this.$wrapper = this.$.find('#mask-content-wrapper');
			this.$wrapper.append($close);

			this.events();
		}

		events() {
			// Obvious way of closing ...
			this.$.on('click', '#mask-close', ev => {
				ev.preventDefault();
				ev.stopPropagation();

				this.hide();
			});

			// Close by using ESC-key
			$('body').on('keydown', ev => {
				let code = ev.charCode
					? ev.charCode
					: ev.keyCode ? ev.keyCode : 0;

				if(code === 27) this.hide();
			});

			// Close by clicking outside the mask
			this.$.on('click', ev => {
				if($(ev.target).closest('#mask-inner').length === 0) {
					this.hide();
				}
			});
		}

		show() {
			this.htmlClass();
			return this;
		}

		hide() {
			this.htmlClass(false);
			this.$wrapper.removeClass('small medium large').removeAttr('style');
			this.$inner.empty();
		}

		htmlClass(show = true) {
			this.$html
				.toggleClass('mask-visible', show)
				.css('padding-right', (show ? $.getScrollbarWidth() : ''));
		}

		setContent(content) {
			this.$inner.empty().append(content);
			return this;
		}

		setSize(cls) {
			this.$wrapper.addClass(cls);
			return this;
		}
	}

	window.mask = new Mask();

});